<template>
  <DashboardTemplateCasual
    v-loading="loading"
    element-loading-text="Loading..."
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
    class="response-report"
    :title="$t('message.responseReport')"
  >
    <div class="title">
      <h1>{{ $t("message.responseReport") }}</h1>
      <div>
        <el-button
          @click="exportActions"
          slot="reference"
          type="primary"
          :loading="loadingExport"
          >Export</el-button
        >
      </div>
    </div>
    <el-row :gutter="10" class="pd-b-3" justify align="middle">
      <el-col :lg="8" :md="12" :sm="12" :xs="24" class="pd-b-4">
        <el-input
          @input="checkFilterList"
          :placeholder="$t('responseReport.findGameSessionId')"
          prefix-icon="el-icon-search"
          v-model="filter.gameSessionId"
          clearable
        ></el-input>
      </el-col>
      <el-col :lg="8" :md="12" :sm="12" :xs="24" class="pd-b-4">
        <el-input
          @input="checkFilterList"
          :placeholder="$t('responseReport.findSessionId')"
          prefix-icon="el-icon-search"
          v-model="filter.sessionId"
          clearable
        ></el-input>
      </el-col>
      <el-col :lg="8" :md="12" :sm="12" :xs="24" class="pd-b-4">
        <el-select
          v-model="filter.gameSessionFinished"
          @change="checkFilterList"
          :placeholder="$t('responseReport.pleaseSelectGameSessionFinished')"
          clearable
          class="w-100"
        >
          <el-option
            :label="$t('responseReport.gameSessionFinishedOption1')"
            :value="true"
          >
          </el-option>
          <el-option
            :label="$t('responseReport.gameSessionFinishedOption2')"
            :value="false"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :lg="8" :md="12" :sm="12" :xs="24" class="pd-b-4">
        <el-select
          v-model="filter.device"
          multiple
          filterable
          remote
          reserve-keyword
          :placeholder="$t('responseReport.pleaseSelectDevice')"
          :remote-method="remoteMethodDevices"
          :loading="loadingDevice"
          class="w-100"
          clearable
          @change="checkFilterList"
        >
          <el-option
            v-for="item in devices"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :lg="8" :md="12" :sm="12" :xs="24" class="pd-b-4">
        <el-select
          v-model="filter.sessionType"
          @change="checkFilterList"
          :placeholder="$t('responseReport.findSessionType')"
          clearable
          class="w-100"
        >
          <el-option label="Casual" value="Casual"> </el-option>
          <el-option label="Period" value="Period"> </el-option>
        </el-select>
      </el-col>
      <el-col :lg="8" :md="12" :sm="12" :xs="24" class="pd-b-4">
        <el-select
          v-model="filter.level"
          multiple
          filterable
          remote
          reserve-keyword
          :placeholder="$t('responseReport.pleaseSelectLevel')"
          :remote-method="remoteMethodLevels"
          :loading="loadingLevel"
          class="w-100"
          clearable
          @change="checkFilterList"
        >
          <el-option
            v-for="item in levels"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
            <span>{{ `${$t("responseReport.level")} ${item.label}` }}</span>
          </el-option>
        </el-select>
      </el-col>
      <el-col :lg="8" :md="24" :sm="24" :xs="24" class="pd-b-4">
        <el-select
          v-model="filter.users"
          multiple
          filterable
          remote
          reserve-keyword
          :placeholder="$t('responseReport.pleaseSelectUser')"
          :remote-method="remoteMethodUsers"
          :loading="loadingUsers"
          class="w-100"
          clearable
          @change="checkFilterList"
        >
          <el-option
            v-for="item in users"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :lg="8" :md="12" :sm="12" :xs="24" class="pd-b-4">
        <el-date-picker
          @change="checkFilterList"
          v-model="filter.timeStartStimuli"
          type="daterange"
          :range-separator="$t('responseReport.timeStartStimuliTo')"
          :start-placeholder="$t('responseReport.timeStartStimuliStart')"
          :end-placeholder="$t('responseReport.timeStartStimuliEnd')"
          class="w-100"
          clearable
        >
        </el-date-picker>
      </el-col>
      <el-col :lg="8" :md="12" :sm="12" :xs="24" class="pd-b-4">
        <el-select
          v-model="sortType"
          @change="checkFilterList"
          :placeholder="$t('responseReport.pleaseSelectSortType')"
          clearable
          class="w-100"
        >
          <el-option
            :label="`${$t('responseReport.sortText')} User_ID`"
            value="userId"
          >
          </el-option>
          <el-option
            :label="`${$t('responseReport.sortText')} GameSession_ID`"
            value="gameSessionId"
          >
          </el-option>
          <el-option
            :label="`${$t('responseReport.sortText')} Timestartstimuli`"
            value="timeStartStimuli"
          >
          </el-option>
        </el-select>
      </el-col>
    </el-row>

    <el-table
      :data="tableData"
      border
      style="width: 100%"
      v-loading="loadingTable"
    >
      <el-table-column
        prop="userId"
        label="User_ID"
        width="80"
        header-align="center"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="userId"
        label="Name"
        width="150"
        header-align="center"
        align="center"
      >
        <template slot-scope="scope">
          <div v-if="getUser(scope.row.userId)">
            <el-avatar
              :size="40"
              :src="getUser(scope.row.userId).profile_photo_url"
            >
            </el-avatar>
            <div>
              {{ getUser(scope.row.userId).name }}
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="gameSessionId"
        label="GameSession_ID"
        width="150"
        header-align="center"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="sessionId"
        label="Session_ID"
        width="150"
        header-align="center"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="sessionType"
        label="SessionType"
        width="110"
        header-align="center"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="gameSessionFinished"
        label="GameSessionFinished"
        width="110"
        header-align="center"
        align="center"
      >
        <template slot-scope="scope">
          <div>
            {{
              scope.row.gameSessionFinished
                ? "ได้เลเวลสูงสุด"
                : "ไม่ได้เลเวลสูงสุด"
            }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="device"
        label="Device"
        header-align="center"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="os"
        label="OS"
        header-align="center"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="browser"
        label="Browser"
        header-align="center"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="testId"
        label="Test"
        width="160"
        header-align="center"
      >
        <template slot-scope="scope">
          <div>{{ getTest(scope.row.testId, "name") }}</div>
        </template>
      </el-table-column>
      <el-table-column
        prop="lang"
        label="Lang"
        width="70"
        header-align="center"
        align="center"
      >
        <template slot-scope="scope">
          <div v-if="getTest(scope.row.testId, 'code') === 'word-recognition'">
            {{ scope.row.lang.toUpperCase() }}
          </div>
          <div v-else>-</div>
        </template>
      </el-table-column>
      <el-table-column
        prop="level"
        label="Level"
        width="70"
        header-align="center"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="trials"
        label="Trials"
        width="70"
        header-align="center"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="itemId"
        label="ItemID"
        width="70"
        header-align="center"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="responseAnswers"
        label="ResponseAnswer"
        width="150"
        header-align="center"
      >
        <template slot-scope="scope">
          <div
            v-if="
              getTest(scope.row.testId, 'code') === 'digit-span–forward' ||
              getTest(scope.row.testId, 'code') === 'digit-span–backward' ||
              getTest(scope.row.testId, 'code') === 'color-span' ||
              getTest(scope.row.testId, 'code') === 'beep-code'
            "
          >
            {{ getKeyVector(scope.row.responseAnswers) }}
          </div>
          <div v-else>-</div>
        </template>
      </el-table-column>
      <el-table-column
        prop="answers"
        label="Answers"
        width="150"
        header-align="center"
      >
        <template slot-scope="scope">
          <div
            v-if="
              getTest(scope.row.testId, 'code') === 'pair-associates-learning'
            "
          >
            {{ getNumberVectorQA(scope.row.answers, "a") }}
          </div>
          <div
            v-else-if="
              getTest(scope.row.testId, 'code') === 'digit-span–forward' ||
              getTest(scope.row.testId, 'code') === 'digit-span–backward'
            "
          >
            {{ getAnswer(scope.row.answers, "answer") }}
          </div>
          <div
            v-else-if="getTest(scope.row.testId, 'code') === 'word-recognition'"
          >
            {{ getBooleanVector(scope.row.answers, "answer") }}
          </div>
          <div
            v-else-if="
              getTest(scope.row.testId, 'code') ===
              'spatial-span-color-sequence'
            "
          >
            <div
              v-if="scope.row.activeColors && scope.row.activeColors.length > 0"
            >
              <div>
                {{
                  `${
                    scope.row.activeColors[0] === 1 ? "Green" : "Yellow"
                  }: ${getNumberVector(
                    scope.row.answers,
                    scope.row.size,
                    scope.row.activeColors[0]
                  )}`
                }}
              </div>
              <div>
                {{
                  `${
                    scope.row.activeColors[1] === 1 ? "Green" : "Yellow"
                  }: ${getNumberVector(
                    scope.row.answers,
                    scope.row.size,
                    scope.row.activeColors[1]
                  )}`
                }}
              </div>
            </div>
          </div>
          <div
            v-else-if="
              getTest(scope.row.testId, 'code') ===
              'spatial-span-working-memory'
            "
          >
            {{
              `${getNumberCollectVector(
                scope.row.answers,
                scope.row.collectAnswers,
                scope.row.correctAnswers,
                scope.row.size
              )}`
            }}
          </div>
          <div
            v-else-if="
              getTest(scope.row.testId, 'code') === 'color-span' ||
              getTest(scope.row.testId, 'code') === 'beep-code'
            "
          >
            {{ getVectorText(scope.row.answers, "answer") }}
          </div>
          <div v-else>
            {{ getNumberVector(scope.row.answers, scope.row.size) }}
          </div>
        </template>
      </el-table-column>

      <el-table-column
        prop="correctAnswers"
        label="Correctanswers"
        width="150"
        header-align="center"
      >
        <template slot-scope="scope">
          <div
            v-if="
              getTest(scope.row.testId, 'code') === 'pair-associates-learning'
            "
          >
            {{ getNumberVectorQA(scope.row.correctAnswers, "q") }}
          </div>
          <div
            v-else-if="
              getTest(scope.row.testId, 'code') === 'digit-span–forward' ||
              getTest(scope.row.testId, 'code') === 'digit-span–backward'
            "
          >
            {{ getAnswer(scope.row.correctAnswers, "correct") }}
          </div>
          <div
            v-else-if="getTest(scope.row.testId, 'code') === 'word-recognition'"
          >
            {{ getBooleanVector(scope.row.correctAnswers, "correct") }}
          </div>
          <div
            v-else-if="
              getTest(scope.row.testId, 'code') ===
              'spatial-span-color-sequence'
            "
          >
            <div
              v-if="scope.row.activeColors && scope.row.activeColors.length > 0"
            >
              <div>
                {{
                  `${
                    scope.row.activeColors[0] === 1 ? "Green" : "Yellow"
                  }: ${getNumberVector(
                    scope.row.correctAnswers,
                    scope.row.size,
                    scope.row.activeColors[0]
                  )}`
                }}
              </div>
              <div>
                {{
                  `${
                    scope.row.activeColors[1] === 1 ? "Green" : "Yellow"
                  }: ${getNumberVector(
                    scope.row.correctAnswers,
                    scope.row.size,
                    scope.row.activeColors[1]
                  )}`
                }}
              </div>
            </div>
          </div>
          <div
            v-else-if="
              getTest(scope.row.testId, 'code') ===
              'spatial-span-working-memory'
            "
          >
            {{
              `${getNumberObjectVector(
                scope.row.correctAnswers,
                scope.row.size
              )}`
            }}
          </div>
          <div
            v-else-if="
              getTest(scope.row.testId, 'code') === 'color-span' ||
              getTest(scope.row.testId, 'code') === 'beep-code'
            "
          >
            {{ getVectorText(scope.row.correctAnswers, "correct") }}
          </div>
          <div v-else>
            {{ getNumberVector(scope.row.correctAnswers, scope.row.size) }}
          </div>
        </template>
      </el-table-column>

      <el-table-column
        prop="timeCollect"
        label="TimeCollect"
        width="150"
        header-align="center"
      >
        <template slot-scope="scope">
          <div
            v-if="
              getTest(scope.row.testId, 'code') ===
              'spatial-span-working-memory'
            "
            v-html="
              getDateTimeCollectVectorHtml(
                scope.row.answers,
                scope.row.collectAnswers
              )
            "
          ></div>
          <div v-else>-</div>
        </template>
      </el-table-column>
      <el-table-column
        prop="isCorrect"
        label="Iscorrect"
        width="80"
        header-align="center"
        align="center"
      >
        <template slot-scope="scope">
          <div>{{ scope.row.isCorrect ? "ถูก" : "ผิด" }}</div>
        </template>
      </el-table-column>
      <el-table-column
        prop="timeStartStimuli"
        label="Timestartstimuli"
        header-align="center"
        align="center"
        width="150"
      >
        <template slot-scope="scope"
          ><div>{{ scope.row.timeStartStimuli | dateTh }}</div>
          <div>{{ scope.row.timeStartStimuli | timeTh }}</div></template
        >
      </el-table-column>
      <el-table-column
        prop="endStimuli"
        label="Endstimuli"
        width="90"
        header-align="center"
        align="center"
      >
        <template slot-scope="scope">
          <div>
            {{
              scope.row.endStimuli === true
                ? "ใช่"
                : scope.row.endStimuli === false
                ? "ไม่"
                : "-"
            }}
          </div>
        </template></el-table-column
      >
      <el-table-column
        prop="timeStartResponse"
        label="Timestartresponse"
        header-align="center"
        align="center"
        width="150"
      >
        <template slot-scope="scope"
          ><div>{{ scope.row.timeStartResponse | dateTh }}</div>
          <div>{{ scope.row.timeStartResponse | timeTh }}</div></template
        >
      </el-table-column>
      <el-table-column
        prop="timeFirstClick"
        label="Timefirstclick"
        header-align="center"
        align="center"
        width="150"
      >
        <template slot-scope="scope"
          ><div>{{ scope.row.timeFirstClick | dateTh }}</div>
          <div>{{ scope.row.timeFirstClick | timeTh }}</div></template
        >
      </el-table-column>
      <el-table-column
        prop="timeresponses"
        label="Timeresponses"
        header-align="center"
        width="160"
      >
        <template slot-scope="scope">
          <div
            v-if="
              getTest(scope.row.testId, 'code') === 'digit-span–forward' ||
              getTest(scope.row.testId, 'code') === 'digit-span–backward' ||
              getTest(scope.row.testId, 'code') === 'color-span' ||
              getTest(scope.row.testId, 'code') === 'beep-code'
            "
            v-html="getDateTimeVectorHtml(scope.row.responseAnswers)"
            class="vector-date-time"
          ></div>
          <div
            v-else-if="
              getTest(scope.row.testId, 'code') ===
              'spatial-span-color-sequence'
            "
          >
            <div
              v-if="scope.row.activeColors && scope.row.activeColors.length > 0"
            >
              <div
                v-html="
                  getDateTimeVectorActiveColorHtml(
                    scope.row.answers,
                    scope.row.activeColors[0]
                  )
                "
              ></div>
              <div
                v-html="
                  getDateTimeVectorActiveColorHtml(
                    scope.row.answers,
                    scope.row.activeColors[1]
                  )
                "
              ></div>
            </div>
          </div>
          <div
            v-else
            v-html="getDateTimeVectorHtml(scope.row.answers)"
            class="vector-date-time"
          ></div>
        </template>
      </el-table-column>

      <el-table-column
        prop="timeLastResponse"
        label="Timelastresponse"
        header-align="center"
        align="center"
        width="150"
      >
        <template slot-scope="scope"
          ><div>{{ scope.row.timeLastResponse | dateTh }}</div>
          <div>{{ scope.row.timeLastResponse | timeTh }}</div></template
        >
      </el-table-column>
      <el-table-column
        prop="numResponseClicks"
        label="Numresponseclicks"
        width="80"
        header-align="center"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="numallClicks"
        label="Numallclicks"
        width="80"
        header-align="center"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="durationStart"
        label="DurationStart"
        header-align="center"
        align="center"
        width="150"
      >
        <template slot-scope="scope"
          ><div v-if="scope.row.durationStart">
            {{ scope.row.durationStart }} วินาที
          </div>
          <div v-else>-</div>
        </template>
      </el-table-column>
      <el-table-column
        prop="durationStimuli"
        label="Durationstimuli"
        width="90"
        header-align="center"
        align="center"
      >
        <template slot-scope="scope"
          ><div>{{ scope.row.durationStimuli }} วินาที</div>
        </template>
      </el-table-column>
      <el-table-column
        prop="durationResponse"
        label="Durationresponse"
        width="90"
        header-align="center"
        align="center"
      >
        <template slot-scope="scope"
          ><div>{{ scope.row.durationResponse }} วินาที</div>
        </template>
      </el-table-column>
      <el-table-column
        prop="isPassTheLevel"
        label="Ispassthelevel"
        width="80"
        header-align="center"
        align="center"
      >
        <template slot-scope="scope">
          <div>{{ scope.row.isPassTheLevel ? "ผ่าน" : "ไม่ผ่าน" }}</div>
        </template></el-table-column
      >
      <el-table-column
        prop="maxLevel"
        label="Maxlevel"
        width="80"
        header-align="center"
        align="center"
      >
      </el-table-column>
    </el-table>
    <div class="pagination-box">
      <el-pagination
        background
        layout="total,prev, pager, next"
        :total="total"
        @current-change="changePage"
        :page-size="limit"
        :current-page="page"
      >
      </el-pagination>
    </div>
  </DashboardTemplateCasual>
</template>

<script>
import DashboardTemplateCasual from "@/template/DashboardTemplateCasual";
import { HTTP, HTTP_WEB_MAIN } from "@/service/axios";

export default {
  components: {
    DashboardTemplateCasual,
  },
  data() {
    return {
      loading: false,
      tableData: [],
      total: 0,
      limit: 10,
      page: 1,
      users: [],
      userConst: [],
      loadingUsers: false,
      loadingDevice: false,
      devices: [],
      deviceConst: [
        { label: "Desktop", value: "Desktop" },
        { label: "Tablet", value: "Tablet" },
        { label: "Mobile", value: "Mobile" },
      ],
      loadingLevel: false,
      levels: [],
      levelConst: [],
      filter: {
        users: [],
        gameSessionId: "",
        sessionType: "",
        sessionId: "",
        gameSessionFinished: "",
        device: [],
        level: [],
        timeStartStimuli: [],
      },
      sortType: "",
      tests: [],
      loadingTable: true,
      debounce: null,
      loadingExport: false,
    };
  },
  watch: {
    "$i18n.locale"() {
      this.$store.commit("SET_BREADCRUMBS", [
        { path: "/", name: "home", i18n: true },
        { path: null, name: this.$t("message.responseReport") },
      ]);
    },
  },
  mounted() {
    this.devices = this.deviceConst;

    this.levelConst = [];
    for (let n = 1; n <= 26; n++) {
      this.levelConst.push({
        label: `${n}`,
        value: n,
      });
    }
    this.levels = this.levelConst;

    this.$store.commit("SET_BREADCRUMBS", [
      { path: "/", name: "home", i18n: true },
      { path: null, name: this.$t("message.responseReport") },
    ]);
    this.fetchTests();
    this.fetchActions();
    this.fetchUsers();
  },
  destroyed() {
    this.$store.commit("SET_BREADCRUMBS", []);
  },
  methods: {
    async fetchTests() {
      try {
        let res = await HTTP.get(`/tests?orderByField=number&orderBy=asc`);

        if (res.data.success) {
          this.tests = res.data.result.rows;
        }
      } catch (e) {
        const error = e.response;
        console.error(error);
      }
    },
    getTest(testId, key) {
      let find = this.tests.find((f) => f._id === testId);
      if (typeof find !== "undefined") {
        return find[key];
      } else {
        return "";
      }
    },
    checkFilterList() {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.page = 1;
        this.loadingTable = true;
        this.fetchActions();
      }, 600);
    },
    remoteMethodUsers(query) {
      if (query !== "") {
        this.loadingUsers = true;
        setTimeout(() => {
          this.loadingUsers = false;

          this.users = this.userConst.filter((item) => {
            return item.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
          });
        }, 200);
      } else {
        this.users = this.userConst;
      }
    },
    remoteMethodDevices(query) {
      if (query !== "") {
        this.loadingDevice = true;
        setTimeout(() => {
          this.loadingDevice = false;

          this.devices = this.deviceConst.filter((item) => {
            return item.value.toLowerCase().indexOf(query.toLowerCase()) > -1;
          });
        }, 200);
      } else {
        this.devices = this.deviceConst;
      }
    },
    remoteMethodLevels(query) {
      if (query !== "") {
        this.loadingLevel = true;
        setTimeout(() => {
          this.loadingLevel = false;

          this.levels = this.levelConst.filter((item) => {
            return item.value.toLowerCase().indexOf(query.toLowerCase()) > -1;
          });
        }, 200);
      } else {
        this.levels = this.levelConst;
      }
    },
    async fetchActions() {
      try {
        let timeStartStimuliStart = null;
        let timeStartStimuliEnd = null;

        if (this.filter.timeStartStimuli) {
          if (this.filter.timeStartStimuli.length > 0) {
            timeStartStimuliStart = new Date(
              this.filter.timeStartStimuli[0]
            ).setHours(0, 0, 0, 0);
            timeStartStimuliEnd = new Date(
              this.filter.timeStartStimuli[1]
            ).setHours(23, 59, 59);
          }
        }

        let obj = {
          users: this.filter.users,
          gameSessionId: this.filter.gameSessionId,
          sessionType: this.filter.sessionType,
          sessionId: this.filter.sessionId,
          gameSessionFinished: this.filter.gameSessionFinished,
          device: this.filter.device,
          level: this.filter.level,
          timeStartStimuliStart: timeStartStimuliStart,
          timeStartStimuliEnd: timeStartStimuliEnd,
          page: this.page,
          size: this.limit,
          orderByField: this.sortType,
          orderBy: "desc",
        };

        let res = await HTTP.post(`/log/actions/get`, obj);

        if (res.data.success) {
          this.tableData = res.data.result.rows;
          this.total = res.data.result.total;
        }
      } catch (e) {
        console.error(e);
        return false;
      } finally {
        this.loadingTable = false;
      }
    },
    async exportActions() {
      try {
        this.loadingExport = true;
        let timeStartStimuliStart = null;
        let timeStartStimuliEnd = null;

        if (this.filter.timeStartStimuli) {
          if (this.filter.timeStartStimuli.length > 0) {
            timeStartStimuliStart = new Date(
              this.filter.timeStartStimuli[0]
            ).setHours(0, 0, 0, 0);
            timeStartStimuliEnd = new Date(
              this.filter.timeStartStimuli[1]
            ).setHours(23, 59, 59);
          }
        }

        let obj = {
          users: this.filter.users,
          gameSessionId: this.filter.gameSessionId,
          sessionType: this.filter.sessionType,
          sessionId: this.filter.sessionId,
          gameSessionFinished: this.filter.gameSessionFinished,
          device: this.filter.device,
          level: this.filter.level,
          timeStartStimuliStart: timeStartStimuliStart,
          timeStartStimuliEnd: timeStartStimuliEnd,
          orderByField: this.sortType,
          orderBy: "desc",
        };

        let res = await HTTP.post(`/log/actions/export`, obj);

        if (res.data.success) {
          const title = [
            "User_ID",
            "Name",
            "GameSession_ID",
            "Session_ID",
            "SessionType",
            "GameSessionFinished",
            "Device",
            "OS",
            "Browser",
            "Test",
            "Lang",
            "Level",
            "Trials",
            "ItemID",
            "ResponseAnswer",
            "Answers",
            "Correctanswers",
            "TimeCollect",
            "Iscorrect",
            "Timestartstimuli",
            "Endstimuli",
            "Timestartresponse",
            "Timefirstclick",
            "Timeresponses",
            "Timelastresponse",
            "Numresponseclicks",
            "Numallclicks",
            "DurationStart",
            "Durationstimuli",
            "Durationresponse",
            "Ispassthelevel",
            "Maxlevel",
          ];

          var datas = [];

          res.data.result.filter((item) => {
            let user = this.getUser(item.userId);

            let gameSessionFinished = "";
            if (item.gameSessionFinished) {
              gameSessionFinished = "ได้เลเวลสูงสุด";
            } else {
              gameSessionFinished = "ไม่ได้เลเวลสูงสุด";
            }

            let responseAnswers = "";

            if (
              this.getTest(item.testId, "code") === "digit-span–forward" ||
              this.getTest(item.testId, "code") === "digit-span–backward" ||
              this.getTest(item.testId, "code") === "color-span" ||
              this.getTest(item.testId, "code") === "beep-code"
            ) {
              responseAnswers = this.getKeyVector(item.responseAnswers);
            } else {
              responseAnswers = "-";
            }

            let answers = "";
            if (
              this.getTest(item.testId, "code") === "pair-associates-learning"
            ) {
              answers = this.getNumberVectorQA(item.answers, "a");
            } else if (
              this.getTest(item.testId, "code") === "digit-span–forward" ||
              this.getTest(item.testId, "code") === "digit-span–backward"
            ) {
              answers = this.getAnswer(item.answers, "answer");
            } else if (
              this.getTest(item.testId, "code") === "word-recognition"
            ) {
              answers = this.getBooleanVector(item.answers, "answer");
            } else if (
              this.getTest(item.testId, "code") ===
              "spatial-span-color-sequence"
            ) {
              if (item.activeColors && item.activeColors.length > 0) {
                answers = `${
                  item.activeColors[0] === 1 ? "Green" : "Yellow"
                }: ${this.getNumberVector(
                  item.answers,
                  item.size,
                  item.activeColors[0]
                )}`;

                answers += ` / ${
                  item.activeColors[1] === 1 ? "Green" : "Yellow"
                }: ${this.getNumberVector(
                  item.answers,
                  item.size,
                  item.activeColors[1]
                )}`;
              }
            } else if (
              this.getTest(item.testId, "code") ===
              "spatial-span-working-memory"
            ) {
              answers = `${this.getNumberCollectVector(
                item.answers,
                item.collectAnswers,
                item.correctAnswers,
                item.size
              )}`;
            } else if (
              this.getTest(item.testId, "code") === "color-span" ||
              this.getTest(item.testId, "code") === "beep-code"
            ) {
              answers = this.getVectorText(item.answers, "answer");
            } else {
              answers = this.getNumberVector(item.answers, item.size);
            }

            let correctanswers = "";
            if (
              this.getTest(item.testId, "code") === "pair-associates-learning"
            ) {
              correctanswers = this.getNumberVectorQA(item.correctAnswers, "q");
            } else if (
              this.getTest(item.testId, "code") === "digit-span–forward" ||
              this.getTest(item.testId, "code") === "digit-span–backward"
            ) {
              correctanswers = this.getAnswer(item.correctAnswers, "correct");
            } else if (
              this.getTest(item.testId, "code") === "word-recognition"
            ) {
              correctanswers = this.getBooleanVector(
                item.correctAnswers,
                "correct"
              );
            } else if (
              this.getTest(item.testId, "code") ===
              "spatial-span-color-sequence"
            ) {
              if (item.activeColors && item.activeColors.length > 0) {
                correctanswers = `${
                  item.activeColors[0] === 1 ? "Green" : "Yellow"
                }: ${this.getNumberVector(
                  item.correctAnswers,
                  item.size,
                  item.activeColors[0]
                )}`;
              }

              correctanswers += ` / ${
                item.activeColors[1] === 1 ? "Green" : "Yellow"
              }: ${this.getNumberVector(
                item.correctAnswers,
                item.size,
                item.activeColors[1]
              )}`;
            } else if (
              this.getTest(item.testId, "code") ===
              "spatial-span-working-memory"
            ) {
              correctanswers = `${this.getNumberObjectVector(
                item.correctAnswers,
                item.size
              )}`;
            } else if (
              this.getTest(item.testId, "code") === "color-span" ||
              this.getTest(item.testId, "code") === "beep-code"
            ) {
              correctanswers = this.getVectorText(
                item.correctAnswers,
                "correct"
              );
            } else {
              correctanswers = this.getNumberVector(
                item.correctAnswers,
                item.size
              );
            }

            let timeCollect = "";
            if (
              this.getTest(item.testId, "code") ===
              "spatial-span-working-memory"
            ) {
              item.answers.map((i, index) => {
                timeCollect += `${this.$options.filters.dateTimeTh(
                  i.dateTime
                )}`;

                if (index < item.answers.length - 1) {
                  timeCollect += ", ";
                }
              });
            } else {
              timeCollect = "-";
            }

            let iscorrect = "";
            if (item.Iscorrect) {
              iscorrect = "ถูก";
            } else {
              iscorrect = "ไม่ถูก";
            }

            let timeStartStimuli = `${this.$options.filters.dateTimeTh(
              item.timeStartStimuli
            )}`;

            let endStimuli = "";
            if (item.endStimuli === true) {
              endStimuli = "ใช่";
            } else if (item.endStimuli === false) {
              endStimuli = "ไม่";
            } else {
              endStimuli = "-";
            }

            let timeStartResponse = `${this.$options.filters.dateTimeTh(
              item.timeStartResponse
            )}`;

            let timeFirstClick = `${this.$options.filters.dateTimeTh(
              item.timeFirstClick
            )}`;

            let timeresponses = "";
            if (
              this.getTest(item.testId, "code") === "digit-span–forward" ||
              this.getTest(item.testId, "code") === "digit-span–backward" ||
              this.getTest(item.testId, "code") === "color-span" ||
              this.getTest(item.testId, "code") === "beep-code"
            ) {
              item.responseAnswers.map((i, index) => {
                timeresponses += `${this.$options.filters.dateTimeTh(
                  i.dateTime
                )}`;

                if (index < item.responseAnswers.length - 1) {
                  timeresponses += ", ";
                }
              });
            } else if (
              this.getTest(item.testId, "code") ===
              "spatial-span-color-sequence"
            ) {
              if (item.activeColors && item.activeColors.length > 0) {
                let answersColor1 = item.answers.filter((f) => f.color == 1);

                timeresponses += "Green: ";
                answersColor1.map((i, index) => {
                  timeresponses += `${this.$options.filters.dateTimeTh(
                    i.dateTime
                  )}`;

                  if (index < answersColor1.length - 1) {
                    timeresponses += ", ";
                  }
                });

                let answersColor2 = item.answers.filter((f) => f.color == 2);

                timeresponses += " / Yellow: ";
                answersColor2.map((i, index) => {
                  timeresponses += `${this.$options.filters.dateTimeTh(
                    i.dateTime
                  )}`;

                  if (index < answersColor2.length - 1) {
                    timeresponses += ", ";
                  }
                });
              }
            } else {
              item.answers.map((i, index) => {
                timeresponses += `${this.$options.filters.dateTimeTh(
                  i.dateTime
                )}`;

                if (index < item.answers.length - 1) {
                  timeresponses += ", ";
                }
              });
            }

            let timeLastResponse = `${this.$options.filters.dateTimeTh(
              item.timeLastResponse
            )}`;

            let durationStart = "";
            if (item.durationStart) {
              durationStart = item.durationStart;
            } else {
              durationStart = "-";
            }

            let isPassTheLevel = "";
            if (item.isPassTheLevel) {
              isPassTheLevel = "ผ่าน";
            } else {
              isPassTheLevel = "ไม่ผ่าน";
            }

            datas.push({
              userId: item.userId,
              name: user ? '"' + user.name + '"' : "",
              gameSessionId: '="' + item.gameSessionId + '"',
              sessionId: '="' + item.sessionId + '"',
              sessionType: item.sessionType,
              gameSessionFinished: gameSessionFinished,
              device: item.device,
              os: item.os,
              browser: item.browser,
              test: this.getTest(item.testId, "name"),
              lang: item.lang.toUpperCase(),
              level: item.level,
              trials: item.trials,
              itemId: item.itemId,
              responseAnswers: '"' + responseAnswers + '"',
              answers: '"' + answers + '"',
              correctanswers: '"' + correctanswers + '"',
              timeCollect: '"' + timeCollect + '"',
              iscorrect: '"' + iscorrect + '"',
              timeStartStimuli: '"' + timeStartStimuli + '"',
              endStimuli: '"' + endStimuli + '"',
              timeStartResponse: '"' + timeStartResponse + '"',
              timeFirstClick: '"' + timeFirstClick + '"',
              timeresponses: '"' + timeresponses + '"',
              timeLastResponse: timeLastResponse,
              numResponseClicks: item.numResponseClicks,
              numallClicks: item.numallClicks,
              durationStart: durationStart,
              durationStimuli: item.durationStimuli,
              durationResponse: item.durationResponse,
              isPassTheLevel: isPassTheLevel,
              maxLevel: item.maxLevel,
            });
          });

          let csvContent = "";
          csvContent += [
            title.join(","),
            ...datas.map((item) => Object.values(item).join(",")),
          ]
            .join("\n")
            .replace(/(^\[)|(\]$)/gm, "");

          var link = window.document.createElement("a");
          link.setAttribute(
            "href",
            "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURI(csvContent)
          );
          link.setAttribute(
            "download",
            "export-response-report-" +
              new Date().getDate() +
              "-" +
              (new Date().getMonth() + 1) +
              "-" +
              new Date().getFullYear() +
              ".csv"
          );
          link.click();
        }
      } catch (e) {
        console.error(e);
        return false;
      } finally {
        this.loadingExport = false;
      }
    },
    async fetchUsers() {
      try {
        let res = await HTTP_WEB_MAIN.get(`/cognitive/users`);

        if (res.status === 200) {
          this.users = res.data;
          this.userConst = res.data;
        }
      } catch (e) {
        console.error(e);
        return false;
      }
    },
    getUser(id) {
      let find = this.users.find((f) => f.id === id);

      if (typeof find !== "undefined") {
        return find;
      } else {
        return null;
      }
    },
    changePage(page) {
      this.page = page;
      this.loadingTable = true;
      this.fetchActions();
    },
    getBooleanVector(answers, type) {
      let booleans = [];
      if (type === "correct") {
        if (answers.length > 0) {
          booleans = answers[0].answer.map((item) => {
            let find = answers[0].question.find((f) => f == item);
            let status = "";
            if (typeof find !== "undefined") {
              status = "YES";
            } else {
              status = "NO";
            }

            return status;
          });
        }
      } else {
        booleans = answers.map((item) => {
          return item.status ? "YES" : "NO";
        });
      }
      return booleans.toString();
    },
    getAnswer(answers, type) {
      let answer = "";
      if (type === "correct") {
        answer = answers.join("");
      } else {
        if (answers.length > 0) {
          answer = answers[0].number;
        }
      }
      return answer;
    },
    getVectorText(answers, type) {
      let texts = [];
      if (type === "answer") {
        if (answers.length > 0) {
          texts = answers[0].number;
        }
      } else {
        texts = answers;
      }
      return texts.toString();
    },
    getKeyVector(options) {
      let numbers = [];

      options.map((item) => {
        numbers.push(item.key);
      });

      return numbers.toString();
    },
    getNumberVector(options, size, activeColor = null) {
      let numbers = [];
      if (activeColor) {
        options = options.filter((f) => f.color == activeColor);
      }

      options.map((item) => {
        let sum = (Number(item.row) - 1) * size;
        let number = Number(item.column) + sum;
        numbers.push(number);
      });

      return numbers.toString();
    },
    getNumberVectorQA(options, type) {
      let number = "";
      if (type === "q") {
        let find = options.find((f) => f.correct);
        number = typeof find !== "undefined" ? find.number : "";
      } else {
        number = options.length > 0 ? options[0].number : "";
      }

      return number;
    },
    getDateTimeVectorHtml(answers) {
      if (answers.length === 0) {
        return "";
      }

      const parent = document.createElement("div");

      let paraTitle = document.createElement("p");
      const title = document.createTextNode(
        `${this.$options.filters.dateTh(answers[0])}`
      );
      paraTitle.appendChild(title);

      parent.appendChild(paraTitle);

      const node = document.createElement("div");

      answers.map((item) => {
        let para = document.createElement("p");
        let dateTime = document.createTextNode(
          `- ${this.$options.filters.timeTh(item.dateTime)}`
        );
        para.appendChild(dateTime);
        node.appendChild(para);
      });
      parent.appendChild(node);

      return parent.innerHTML;
    },
    getDateTimeVectorActiveColorHtml(answers, activeColor) {
      answers = answers.filter((f) => f.color == activeColor);

      const parent = document.createElement("div");

      let paraTitle = document.createElement("p");
      const title = document.createTextNode(
        `${activeColor == 1 ? "Green" : "Yellow"}: ${
          answers.length > 0 ? this.$options.filters.dateTh(answers[0]) : ""
        }`
      );
      paraTitle.appendChild(title);

      parent.appendChild(paraTitle);

      if (answers.length > 0) {
        const node = document.createElement("div");

        answers.map((item) => {
          let para = document.createElement("p");
          let dateTime = document.createTextNode(
            `- ${this.$options.filters.timeTh(item.dateTime)}`
          );
          para.appendChild(dateTime);
          node.appendChild(para);
        });

        parent.appendChild(node);
      }
      return parent.innerHTML;
    },
    getNumberObjectVector(options, size) {
      let numbers = [];
      options.map((item) => {
        let sum = (Number(item.row) - 1) * size;
        let number = Number(item.column) + sum;
        numbers.push(`${number}${item.object ? ": มีวัตถุ" : ": ไม่มีวัตถุ"}`);
      });

      return numbers.toString();
    },
    getNumberCollectVector(answers, collectAnswers, correctAnswers, size) {
      let numbers = [];
      answers.map((item) => {
        let findCollect = collectAnswers.find(
          (f) => f.row === item.row && f.column === item.column
        );

        let correct = false;
        let findCorrect = correctAnswers.find(
          (f) => f.row === item.row && f.column === item.column
        );

        if (typeof findCorrect !== "undefined") {
          if (findCorrect.object) {
            if (typeof findCollect !== "undefined") {
              correct = true;
            } else {
              correct = false;
            }
          } else {
            if (typeof findCollect !== "undefined") {
              correct = false;
            } else {
              correct = true;
            }
          }
        } else {
          correct = false;
        }

        let sum = (Number(item.row) - 1) * size;
        let number = Number(item.column) + sum;
        numbers.push(
          `${number}${
            typeof findCollect !== "undefined" ? ": เก็บ" : ": ไม่เก็บ"
          }${correct ? "(ถูก)" : "(ผิด)"}`
        );
      });

      return numbers.toString();
    },
    getDateTimeCollectVectorHtml(answers, collectAnswers) {
      if (answers.length === 0) {
        return "";
      }

      const parent = document.createElement("div");

      let paraTitle = document.createElement("p");
      const title = document.createTextNode(
        `${this.$options.filters.dateTh(answers[0])}`
      );
      paraTitle.appendChild(title);

      parent.appendChild(paraTitle);

      const node = document.createElement("div");

      answers.map((item) => {
        let para = document.createElement("p");
        let dateTime = "";

        let findCollect = collectAnswers.find(
          (f) => f.row === item.row && f.column === item.column
        );
        if (typeof findCollect !== "undefined") {
          dateTime = document.createTextNode(
            `- ${this.$options.filters.timeTh(item.dateTime)}`
          );
        } else {
          dateTime = document.createTextNode(`- null`);
        }

        para.appendChild(dateTime);
        node.appendChild(para);
      });
      parent.appendChild(node);

      return parent.innerHTML;
    },
  },
};
</script>

<style lang="scss" scoped>
.response-report {
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
</style>